import { NumberInput, NumberInputField } from '@chakra-ui/react';
import { useState, type ComponentPropsWithoutRef, type RefObject, useEffect } from 'react';

import type { Input } from './Input';
import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

type IntegerInputProps = Omit<ComponentPropsWithoutRef<typeof Input>, 'onChange' | 'value'> &
  Omit<InputWrapperProps, 'children'> & {
    innerRef?: RefObject<HTMLInputElement>;
    inputClassName?: string;
    value?: number;
    onChange?: (value: number) => void;
  };

export const IntegerInput = ({
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  inputClassName,
  innerRef,
  value,
  onChange,
  ...restProps
}: IntegerInputProps) => {
  const [valAsStr, setValAsStr] = useState(value?.toString() ?? '');
  useEffect(() => {
    if (!value) return;
    setValAsStr(value.toString());
  }, [value]);

  return (
    <NumberInput
      as={InputWrapper}
      className={className}
      name={name}
      label={label}
      error={error}
      leftElements={leftElements}
      rightElements={rightElements}
      value={valAsStr}
      onChange={(valStr, valNum) => {
        setValAsStr(valStr);
        onChange?.(isNaN(valNum) ? 0 : valNum);
      }}>
      <NumberInputField
        name={name}
        id={name}
        className={inputClassName}
        ref={innerRef}
        aria-invalid={error ? 'true' : 'false'}
        {...restProps}
      />
    </NumberInput>
  );
};
