import clsx from 'clsx';

import styles from './Input.module.scss';
import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

type CheckboxProps = JSX.IntrinsicElements['input'] & Omit<InputWrapperProps, 'children'> & { inputClassName?: string };

export const Switch = ({
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  inputClassName,
  ...restProps
}: CheckboxProps) => {
  return (
    <InputWrapper
      className={clsx(styles['switch-wrapper'], className)}
      name={name}
      label={label}
      error={error}
      leftElements={leftElements}
      rightElements={rightElements}>
      <label className={styles['switch-label']} htmlFor={name}>
        <input type='checkbox' name={name} id={name} {...restProps} />
        <span />
      </label>
    </InputWrapper>
  );
};
