import type { ComponentPropsWithoutRef } from 'react';

import { TokenAmountInput } from '../Inputs';
import type { MinimalToken } from '../Inputs/TokenAmountInput';

import { useUsefulField } from './useUsefulField';

type FormTokenAmountInputProps<UsedToken extends MinimalToken> = Omit<
  ComponentPropsWithoutRef<typeof TokenAmountInput<UsedToken>>,
  'defaultValue' | 'error' | 'onBlur' | 'onChange' | 'onTokenSelect' | 'selectedToken' | 'value'
> & { name: string; tokenFieldName: string };

export const FormTokenAmountInput = <UsedToken extends MinimalToken = MinimalToken>({
  name,
  tokenFieldName,
  ...restProps
}: FormTokenAmountInputProps<UsedToken>) => {
  const { value: amount, setValue: setAmount, ...amountField } = useUsefulField<bigint>(name);
  const { value: token, setValue: setToken, ...tokenField } = useUsefulField<UsedToken>(tokenFieldName);

  const combinedTouched = amountField.touched || tokenField.touched;
  const combinedError = amountField.error ?? tokenField.error;

  return (
    <TokenAmountInput
      value={amount}
      onChange={a => setAmount(a)}
      selectedToken={token}
      onTokenSelect={t => setToken(t)}
      error={combinedTouched && combinedError ? combinedError : undefined}
      onBlur={() => {
        !tokenField.touched && tokenField.setTouched(true);
        !amountField.touched && amountField.setTouched(true);
      }}
      {...restProps}
    />
  );
};
