import { Image } from '@chakra-ui/image';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/portal';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, SyntheticEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { formatUnits } from 'viem';

import { useIsMobile } from '@endaoment-frontend/hooks';
import type { EVMToken, TokenBalance } from '@endaoment-frontend/types';
import { DownCaretIcon } from '@endaoment-frontend/ui/icons';
import { Card, Loader, Pill } from '@endaoment-frontend/ui/shared';
import { formatNumber } from '@endaoment-frontend/utils';

import { BigNumberInput } from './BigNumberInput';
import { Input } from './Input';
import styles from './TokenAmountInput.module.scss';

export type MinimalToken = Pick<EVMToken, 'contractAddress' | 'decimals' | 'logoUrl' | 'symbol'>;
type TokenListProps<UsedToken extends MinimalToken> = {
  tokenSearchValue: string;
  onTokenSearch: (value: string) => void;
  onTokenSelect: (token: UsedToken, balance: bigint) => void;
  tokenBalances?: Array<TokenBalance<UsedToken>>;
};
type TokenAmountInputProps<UsedToken extends MinimalToken> = Omit<
  ComponentPropsWithoutRef<typeof BigNumberInput>,
  'className' | 'onChange' | 'rightElements' | 'units' | 'value'
> &
  TokenListProps<UsedToken> & {
    tokenHeld: bigint;
    selectedToken: UsedToken;
    value: bigint;
    onChange: (value: bigint) => void;
    className?: string;
    innerClassName?: string;
  };

export const TokenAmountInput = <UsedToken extends MinimalToken = MinimalToken>({
  selectedToken,
  tokenHeld,
  tokenBalances,
  value,
  onChange,
  tokenSearchValue,
  onTokenSearch,
  onTokenSelect,
  className,
  innerClassName,
  ...props
}: TokenAmountInputProps<UsedToken>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTokenOptionsVisible, setIsTokenOptionsVisible] = useState(false);

  const { isMobile } = useIsMobile({ defaultState: true });

  const handleChange = useCallback(
    (n: bigint) => {
      if (n > tokenHeld) {
        onChange(tokenHeld);
        return;
      }

      onChange(n);
    },
    [onChange, tokenHeld],
  );

  return (
    <div className={clsx(styles.input, className)} data-testid='token-amount-input' ref={containerRef}>
      <BigNumberInput
        value={value}
        units={selectedToken.decimals}
        onChange={handleChange}
        className={innerClassName}
        inputClassName={clsx(styles['input-inner'], props.inputClassName)}
        rightElements={
          <>
            <span className={styles.balance}>
              Balance:&nbsp;
              {formatNumber(formatUnits(tokenHeld, selectedToken.decimals), {
                compact: false,
                digits: 16,
                fractionDigits: 3,
                stripZeros: true,
              })}
              &nbsp;
              {selectedToken.symbol}
            </span>
            <Pill size='tiny' variation='blue' onClick={() => onChange(tokenHeld)} className={styles['max-button']}>
              MAX
            </Pill>
            <Popover
              autoFocus={!isMobile}
              isOpen={isTokenOptionsVisible}
              onClose={() => setIsTokenOptionsVisible(false)}
              placement='top'>
              <PopoverTrigger>
                <button
                  type='button'
                  className={clsx(styles['select-token'], isTokenOptionsVisible && styles['select-token--active'])}
                  onClick={() => {
                    setIsTokenOptionsVisible(v => !v);
                  }}>
                  {!!selectedToken.logoUrl && <Image src={selectedToken.logoUrl} alt='' width='20px' height='20px' />}
                  {selectedToken.symbol}
                  <DownCaretIcon />
                </button>
              </PopoverTrigger>
              <Portal containerRef={containerRef}>
                <PopoverContent>
                  <TokenList
                    tokenSearchValue={tokenSearchValue}
                    onTokenSearch={onTokenSearch}
                    onTokenSelect={(...args) => {
                      onTokenSelect(...args);
                      setIsTokenOptionsVisible(false);
                    }}
                    tokenBalances={tokenBalances}
                  />
                </PopoverContent>
              </Portal>
            </Popover>
          </>
        }
        {...props}
      />
    </div>
  );
};

const TokenList = <UsedToken extends MinimalToken>({
  tokenSearchValue,
  onTokenSearch,
  onTokenSelect,
  tokenBalances,
}: TokenListProps<UsedToken>) => {
  return (
    <Card className={styles['options-floater']} onClick={(e: SyntheticEvent) => e.stopPropagation()}>
      <Input
        type='search'
        className={styles['options-search']}
        onChange={e => onTokenSearch(e.currentTarget.value)}
        placeholder='Type the name or symbol of your token'
        value={tokenSearchValue}
      />
      <ul className={styles['token-options']}>
        {tokenBalances ? (
          tokenBalances.map(({ token, balance }) => (
            <li
              key={token.symbol + token.contractAddress}
              onClick={() => onTokenSelect(token, balance)}
              className={styles['token-option']}>
              <span>
                {!!token.logoUrl && <Image src={token.logoUrl} alt='' width='20px' height='20px' loading='lazy' />}
                <span>{token.symbol}</span>
              </span>
              <span>
                {formatNumber(formatUnits(balance, token.decimals), {
                  digits: 4,
                })}
              </span>
            </li>
          ))
        ) : (
          <li className={styles['token-loader']}>
            <Loader />
          </li>
        )}
      </ul>
    </Card>
  );
};
