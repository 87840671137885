import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './Input.module.scss';
import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

export type DropDownItem = { value: number | string; display: ReactNode };

type InputProps = JSX.IntrinsicElements['select'] &
  Omit<InputWrapperProps, 'children'> & {
    options: Array<DropDownItem>;
    inputClassName?: string;
  };

export const Select = ({
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  inputClassName,
  placeholder,
  options,
  ...restProps
}: InputProps) => {
  return (
    <InputWrapper
      className={className}
      name={name}
      label={label}
      error={error}
      leftElements={leftElements}
      rightElements={rightElements}>
      <select name={name} id={name} className={clsx(styles.select, inputClassName)} {...restProps}>
        {!!placeholder && (
          <option value='' disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    </InputWrapper>
  );
};
