import clsx from 'clsx';
import type { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import { useEffect, useRef, useState } from 'react';
import { formatUnits, parseUnits } from 'viem';

import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './BigNumberInput.module.scss';
import { Input } from './Input';

export type BigNumberInputProps = Omit<ComponentPropsWithoutRef<typeof Input>, 'onBlur' | 'onChange' | 'value'> & {
  value?: bigint;
  onChange?: (num: bigint) => void;
  onBlur?: () => void;
  units: number;
  isDollars?: boolean;
};

const formatInternal = (num: bigint, options: { units: number; isDollars: boolean }): string => {
  if (num === 0n) return '';

  const formatted = formatUnits(num, options.units);
  if (options.isDollars) {
    return formatCurrency(formatted, { fraction: 2 }).slice(1).replace(/,/g, '');
  }

  return formatted;
};

const parseInternal = (str: string, units: number): bigint | null => {
  // Do not allow underflow
  const decimalIndex = str.indexOf('.');
  if (decimalIndex !== -1 && str.slice(decimalIndex + 1).length > units) return null;

  return parseUnits(str, units);
};

const inputRegEx = /^\d*[,.]?\d*$/;

const shouldSetInternal = (str: string, units: number): boolean => {
  if (
    // Only allow valid number formats
    !inputRegEx.test(str) ||
    // Do not allow updating if the new input will create a value that doesn't fit in the units/decimals representation
    !fitsUnits(str, units)
  )
    return false;

  // Clean up raw
  return true;
};

const cleanUp = (str: string) => str.replace(',', '.');

const fitsUnits = (str: string, units: number) => {
  const decimalIndex = str.indexOf('.');
  return decimalIndex === -1 || str.slice(decimalIndex + 1).length <= units;
};

export const BigNumberInput = ({
  value = 0n,
  onChange,
  onBlur,
  units,
  isDollars = false,
  ...props
}: BigNumberInputProps) => {
  const [internalValue, setInternalValue] = useState(() => formatInternal(value, { units, isDollars }));
  const unitsRef = useRef(units);

  useEffect(() => {
    // Reset values in case parsed value with updated units is skewed
    if (unitsRef.current !== units) {
      setInternalValue('');
      onChange?.(0n);
      unitsRef.current = units;
    } else if (parseInternal(internalValue, units) !== value) {
      setInternalValue(formatInternal(value, { units, isDollars }));
    }
  }, [internalValue, isDollars, onChange, units, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const raw = e.currentTarget.value;

    if (shouldSetInternal(raw, units)) {
      const clean = cleanUp(raw);
      setInternalValue(clean);

      const parsed = parseInternal(clean, units);
      if (parsed !== null && parsed !== value) onChange?.(parsed);
    }
  };

  const handleBlur = () => {
    // Reformats user input to be valid once they are finished typing
    if (isDollars) {
      setInternalValue(prev => formatCurrency(prev, { fraction: 2 }).slice(1).replace(/,/g, ''));
    }

    onBlur?.();
  };

  return (
    <Input
      value={internalValue}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder='0'
      inputMode='decimal'
      autoComplete='off'
      autoCorrect='off'
      type='text'
      pattern='^[0-9]*[.,]?[0-9]*$'
      minLength={1}
      maxLength={24}
      spellCheck='false'
      leftElements={isDollars ? <span>$</span> : false}
      inputClassName={clsx(styles['right-align'], props.inputClassName)}
      {...props}
    />
  );
};
