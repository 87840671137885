import { useField } from 'formik';
import type { ChangeEvent, ComponentProps } from 'react';
import { useCallback, useEffect } from 'react';

import { Input, Select } from '../Inputs';
import type { DropDownItem } from '../Inputs/Select';

type ReferralFormInputProps = Omit<
  ComponentProps<typeof Select>,
  'label' | 'name' | 'onChange' | 'options' | 'value'
> & {
  referralFieldName: string;
};

type ReferralComplementOptions = {
  /**
   * The value of the option displayed in the dropdown
   */
  label: string;

  /**
   * Potential values for the complement field. If not provided, the complement field will be a free text input.
   */
  possibleValues?: Array<string>;
};

/**
 * A map of referral sources to their complement options. If a referral source has no complement field, set the value to
 * `undefined`.
 *
 * Referral complements are used to capture additional information about the referral source. For example, if the referral
 * source is a financial advisor, the complement field could be used to capture the name of the financial advisor.
 */
const REFERRAL_OPTIONS_MAP: Record<string, ReferralComplementOptions | undefined> = {
  'Google': undefined,
  'DAFSquatch': undefined,
  'Friends/Family': {
    label: 'Who referred you?',
  },
  'Financial Advisor': {
    label: 'Who referred you?',
  },
  'CPA': {
    label: 'Who referred you?',
  },
  'Philanthropic Advisor': {
    label: 'Who referred you?',
  },
  'Conference/Event': {
    label: 'Which conference/event did you hear about us from?',
  },
  'Social Media': {
    label: 'Which social media platform did you hear about us from?',
    possibleValues: ['Twitter', 'Farcaster', 'Instagram', 'LinkedIn', 'Facebook', 'Other'],
  },
  'Other': { label: 'Please specify' },
};

const WHERE_DID_YOU_HEAR_ABOUT_US_OPTIONS = Object.keys(REFERRAL_OPTIONS_MAP);

const DEFAULT_REFERRAL = '';
const DEFAULT_COMPLEMENT = '';

/**
 * A form input for capturing referral information. This input consists of two fields: a dropdown for the referral source
 * and a complement field for additional information about the referral source.
 * @param referralFieldName - The name of the form field holding the state for the referral input.
 * @param props - Additional props to pass to the underlying `Select` and `Input` components.
 * @constructor
 */
export const ReferralFormInput = ({ referralFieldName, ...props }: ReferralFormInputProps) => {
  const [{ value: referralFormField }, , { setValue: setReferralFormField }] = useField<string>(referralFieldName);

  const referralSplit = referralFormField?.split('|');
  const referral = referralSplit?.[0]?.trim() ?? DEFAULT_REFERRAL;
  const complement = referralSplit?.[1] ?? DEFAULT_COMPLEMENT;

  const handleReferralFieldChange = useCallback(
    (ref: string, comp: string) => {
      let referralFormValue = ref;
      if (comp) {
        referralFormValue += `|${comp}`;
      }

      setReferralFormField(referralFormValue, true);
    },
    [setReferralFormField],
  );

  const setReferralCallback = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const newReferral = e.currentTarget.value;

      // Clear complement field if referral changes
      if (newReferral !== referral) {
        handleReferralFieldChange(newReferral, '');
      } else {
        handleReferralFieldChange(newReferral, complement);
      }
    },
    [referral, complement, handleReferralFieldChange],
  );

  const setComplementCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      handleReferralFieldChange(referral, e.currentTarget.value);
    },
    [referral, handleReferralFieldChange],
  );

  // Every time the referral or complement changes, update the form field.
  useEffect(() => {
    let referralFormValue = referral;
    if (complement) {
      referralFormValue += `|${complement}`;
    }

    setReferralFormField(referralFormValue, true);
  }, [referral, complement, setReferralFormField]);

  const complementOption = REFERRAL_OPTIONS_MAP[referral];

  // TODO: Look into why the "with" pattern is not working here. A demo of the problem can be found on the branch
  //  "with-pattern".
  const renderComplement = () => {
    // If no complement option is provided, don't render a complement field.
    if (!complementOption) return null;

    // If the complement option has no possible values, render a free text input.
    const possibleValues = complementOption.possibleValues;
    if (!possibleValues) {
      return <Input label={complementOption.label} value={complement} onChange={setComplementCallback} />;
    }

    // If the complement option has possible values, render a dropdown.
    return (
      <Select
        label={complementOption.label}
        value={complement}
        placeholder='Select an Option'
        options={possibleValues.map(
          (o): DropDownItem => ({
            display: o,
            value: o,
          }),
        )}
        onChange={setComplementCallback}
        {...props}
      />
    );
  };

  return (
    <>
      <Select
        label='Where did you hear about us? (optional)'
        placeholder='Select an Option'
        value={referral}
        options={WHERE_DID_YOU_HEAR_ABOUT_US_OPTIONS.map(
          (o): DropDownItem => ({
            display: o,
            value: o,
          }),
        )}
        onChange={setReferralCallback}
        {...props}
      />
      {renderComplement()}
    </>
  );
};
